class GalleryCard extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        this.render();
    }

    set galleryData(data) {
        this._galleryData = data;        
        this.render();
    }

    render() {
        if (!this._galleryData) return;

        
        const {
            galleryId,
            galleryAddress,
            galleryTitle,
            galleryLink,
            galleryLat,
            galleryLon,
            countryId,
            countryTitle,
        } = this._galleryData;
        
        const template = document.createElement('template');
        template.innerHTML = `
          <style>
:host {
    display: block;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 12px;                 
}

.gallery-card {
    display: flex;
    height: 100%;
    border-radius: 12px;
padding: 6px 2px;
}

.gallery-card-image {
    width: 84px; 
    height: 84px; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-card-content {
    display: flex;
    flex-direction: column;
    padding: 4px 10px;
    flex-grow: 1;
}

.gallery-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between h3 and SVG */
    margin-bottom: 4px; /* Adjust if needed */
}

.gallery-card-content h3 {
    margin: 0;
    font-size: 1.1em;
    color: #333;
    font-weight: 600;
}

.gallery-card-content a {
    text-decoration: none;
    color: #333;    
}

.gallery-card-content p {
    margin: 5px 0;
    color: #666;
    font-size: 1em;
}

.gallery-card-content p:last-of-type {
    margin-top: auto;
}

          </style>
          <div class="gallery-card">
            <div class="gallery-card-image">
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-pavilion" width="84" height="84" viewBox="0 0 24 24" stroke-width=".7" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 21h7v-3a2 2 0 0 1 4 0v3h7" />
  <path d="M6 21l0 -9" />
  <path d="M18 21l0 -9" />
  <path d="M6 12h12a3 3 0 0 0 3 -3a9 8 0 0 1 -9 -6a9 8 0 0 1 -9 6a3 3 0 0 0 3 3" />
</svg></div>
            <div class="gallery-card-content">
            <div class="gallery-card-header">


              <h3>${galleryTitle}</h3>
<a href="${galleryLink}"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-external-link" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="#9e9e9e" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
  <path d="M11 13l9 -9" />
  <path d="M15 4h5v5" />
</svg></a>
</div>
              <p>${galleryAddress}, ${countryTitle}</p>
            </div>
          </div>
        `;

        this.shadowRoot.innerHTML = '';
        this.shadowRoot.appendChild(template.content.cloneNode(true));
        const galleryCard = this.shadowRoot.querySelector('.gallery-card');

        galleryCard.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('gallery-click', {
                detail: {
                    lat: galleryLat,
                    lon: galleryLon
                },
                bubbles: true,
                composed: true
            }));
        });

    }
}

customElements.define('gallery-card', GalleryCard);
