class MiniArtCard extends HTMLElement {
    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        this.render();
    }

    set artworkData(data) {
        this._artworkData = data;
        this.render();
    }

    render() {
        if (!this._artworkData) return;

        const { artworkTitle,
            artworkYear,
            artworkStyle,
            artworkMedium,
            artworkDimensions,
            galleryTitle,
            countryId,
            countryTitle,
            artistTitle,
            imageUrl,
            galleryLat,
            galleryLon } = this._artworkData;

        const template = document.createElement('template');
        template.innerHTML = `
          <style>
            :host {
              border: 1px solid #e0e0e0;
              background-color: #ffffff;
              box-sizing: border-box;
              border-radius: 12px;                 
              }

            .mini-art-card-image {
              height: 96px; 
              background-image: url('${imageUrl}');
              background-size: cover; 
              background-repeat: no-repeat;
              background-position-x: left;
              background-position-y: top;
              flex-shrink: 0; /* prevent image from shrinking */
            }

          </style>
            <div class="mini-art-card-image"></div>
        `;

        this.shadowRoot.innerHTML = '';
        this.shadowRoot.appendChild(template.content.cloneNode(true));

        const artCard = this.shadowRoot.querySelector('.mini-art-card-image');
        let holdTimer;

        const holdDuration = 1000;

        // Function to start the hold timer
        function startHoldTimer() {
            holdTimer = setTimeout(() => {
                // Trigger your hold action here
                this.dispatchEvent(new CustomEvent('artwork-hold', {
                    detail: {
                        artistTitle,
                        artworkTitle,
                        artworkYear,
                        artworkStyle,
                        artworkMedium,
                        artworkDimensions,
                        galleryTitle,
                        countryTitle,
                        countryId,
                        artistTitle,
                        imageUrl,
                    },
                    bubbles: true,
                    composed: true
                }));
            }, holdDuration);
        }

        // Function to clear the hold timer
        function clearHoldTimer() {
            clearTimeout(holdTimer);
        }

        // Event listeners for mouse events (desktop)
        artCard.addEventListener('mousedown', startHoldTimer.bind(this), { passive: true });
        artCard.addEventListener('mouseup', clearHoldTimer, { passive: true });
        artCard.addEventListener('mouseleave', clearHoldTimer, { passive: true });

        // Event listeners for touch events (mobile)
        artCard.addEventListener('touchstart', startHoldTimer.bind(this), { passive: true });
        artCard.addEventListener('touchend', clearHoldTimer, { passive: true });
        artCard.addEventListener('touchcancel', clearHoldTimer, { passive: true });

        // Event listener for a normal click or tap
        artCard.addEventListener('click', () => {
            clearHoldTimer(); // Ensure hold doesn't trigger
            this.dispatchEvent(new CustomEvent('artwork-click', {
                detail: {
                    lat: galleryLat,
                    lon: galleryLon
                },
                bubbles: true,
                composed: true
            }));
        });



    }
}

customElements.define('mini-art-card', MiniArtCard);
