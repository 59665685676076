import { updateMapWithArtworks } from './map.js';
import { Spinner } from './spinner.js';

const extractUniqueGalleries = (artworkData) => {
    const uniqueGalleries = artworkData.reduce((acc, current) => {
        const galleryExists = acc.find(gallery => gallery.galleryId === current.galleryId);
        if (!galleryExists) {
            acc.push({
                galleryId: current.galleryId,
                galleryTitle: current.galleryTitle,
                galleryAddress: current.galleryAddress,
                galleryLat: current.galleryLat,
                galleryLon: current.galleryLon,
                galleryLink: `https://www.${current.galleryTitle.toLowerCase().replace(/\s+/g, '')}.com/`
            });
        }
        return acc;
    }, []);

    return uniqueGalleries;
};


async function updateArtworks(data, map) {
    
    let cardContainer = document.querySelector('.large-card-container-cards');
    let miniCardContainer = document.querySelector('.mini-card-container');

    let artistSelect = document.querySelector('.artist-select');
    let gallerySelect = document.querySelector('.gallery-select');

    let messageElement = document.querySelector('.no-artworks-message');
    messageElement.style.display = 'none';
    messageElement.style.marginTop = '0';

    // Clear out old select options (keeping the default empty option)
    artistSelect.innerHTML = '<option value="">Select Artist</option>';
    gallerySelect.innerHTML = '<option value="">Select Gallery</option>';

    // If no artworks found, show the message and clear the containers
    if (data.message) {
        cardContainer.innerHTML = '';
        miniCardContainer.innerHTML = '';
        messageElement.style.display = 'block';
        messageElement.style.marginTop = '154px';
        return;
    }

    const artworksMap = new Map(data.map(artwork => [artwork.artworkId, artwork]));

    // Clear any existing cards
    cardContainer.innerHTML = '';
    miniCardContainer.innerHTML = '';

    // Sets to store unique artist and gallery details
    const artistMap = new Map();
    const galleryMap = new Map();

    // Create document fragments for cards and selects
    const cardFragment = document.createDocumentFragment();
    const miniCardFragment = document.createDocumentFragment();
    const artistSelectFragment = document.createDocumentFragment();
    const gallerySelectFragment = document.createDocumentFragment();

    // Iterate over artworks and update cards and select options
    artworksMap.forEach((artwork) => {
        const artCard = document.createElement('art-card');
        artCard.artworkData = artwork;
        cardFragment.appendChild(artCard);

        const miniArtCard = document.createElement('mini-art-card');
        miniArtCard.artworkData = artwork;
        miniCardFragment.appendChild(miniArtCard);
        
        // Store artist info in a map (artistId => artistTitle)
        if (artwork.artistId && artwork.artistTitle) {
            artistMap.set(artwork.artistId, artwork.artistTitle);
        }

        // Store gallery info in a map (galleryId => galleryTitle)
        if (artwork.galleryId && artwork.galleryTitle) {
            galleryMap.set(artwork.galleryId, artwork.galleryTitle);
        }
    });

    // Append the mini cards to the DOM before updating the map
    miniCardContainer.appendChild(miniCardFragment);

    await updateMapWithArtworks(artworksMap, map);

    // Append the large cards and populate the selects
    cardContainer.appendChild(cardFragment);
    
    // Populate artist select options
    artistMap.forEach((artistTitle, artistId) => {
        const option = document.createElement('option');
        option.value = artistId;
        option.textContent = artistTitle;
        artistSelectFragment.appendChild(option);
    });
    artistSelect.appendChild(artistSelectFragment);

    // Populate gallery select options
    galleryMap.forEach((galleryTitle, galleryId) => {
        const option = document.createElement('option');
        option.value = galleryId;
        option.textContent = galleryTitle;
        gallerySelectFragment.appendChild(option);
    });
    gallerySelect.appendChild(gallerySelectFragment);
}


function updateGalleries(data) {
    let cardContainer = document.querySelector('.large-gallery-container-cards');
    let messageElement = document.querySelector('.no-galleries-message');
    messageElement.style.display = 'none';

    // Show "no galleries found" message if data contains a message
    if (data.message) {
        cardContainer.innerHTML = '';
        messageElement.style.display = 'block';
        return;
    }

    const galleriesMap = new Map(data.map((gallery) => [gallery.galleryId, gallery]));

    // Clear existing cards
    cardContainer.querySelectorAll('gallery-card').forEach(card => card.remove());

    const fragment = document.createDocumentFragment(); // Create a document fragment

    galleriesMap.forEach((gallery) => {
        const galleryCard = document.createElement('gallery-card');
        galleryCard.galleryData = gallery;
        fragment.appendChild(galleryCard); // Append gallery-card to the fragment
    });

    cardContainer.appendChild(fragment);
}

export async function fetchNearbyArtworks(lat, long, map, radius = 100) {
    Spinner.show(); // Show the spinner before starting the fetch process
    const url = `/api/artworks_nearby/${lat}/${long}/${radius}`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        updateArtworks(data, map); // Update artworks on the map
    } catch (error) {
        console.error('There was an error fetching by radius:', error);
    } finally {
        Spinner.hide(); 
    }
}

export async function fetchNearbyGalleries(lat, long, radius = 100) {
    const url = `/api/galleries_nearby/${lat}/${long}/${radius}`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        updateGalleries(data);
    } catch (error) {
        console.error('There was an error fetching by radius:', error);
    }
}


export async function fetchHighlightedArtworks(map) {
    Spinner.show();
    const url = `/api/artworks_highlights`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const galleryData = extractUniqueGalleries(data);
        updateGalleries(data);
        updateArtworks(data, map);
    } catch (error) {
        console.error('There was an error fetching by highlights:', error);
    } finally {
        Spinner.hide();
    }
}

export async function fetchArtists() {
    const url = `/api/artists`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const artistSelect = document.querySelector('.menu-artist-select');
        const fragment = document.createDocumentFragment(); // Create a document fragment
        // Loop through the data and create an option for each artist
        data.forEach(artist => {
            const option = document.createElement('option');
            option.value = artist.artistId;
            option.textContent = artist.artistTitle;
            fragment.appendChild(option); // Append each option to the fragment
        });
        // Append the fragment to the select element in one go
        artistSelect.appendChild(fragment);
    } catch (error) {
        console.error('There was an error fetching the artists:', error);
    }
}

export async function fetchCountries() {
    const url = `/api/countries`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();        
        const countrySelect = document.querySelector('.menu-country-select');
        const fragment = document.createDocumentFragment(); // Create a document fragment
        // Loop through the data and create an option for each country
        data.forEach(country => {
            const option = document.createElement('option');
            option.value = country.countryId;
            option.textContent = country.countryTitle;
            fragment.appendChild(option); // Append each option to the fragment
        });
        // Append the fragment to the select element in one go
        countrySelect.appendChild(fragment);
    } catch (error) {
        console.error('There was an error fetching the countries:', error);
    }
}

export function getSelectedRadius() {
    const selectElement = document.querySelector('.radius-select');
    return parseInt(selectElement.value, 10);
}


export async function getArtistArtworks(map, artistid) {
    Spinner.show();
    try {
        const url = `/api/artist_artworks/${artistid}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (!data.error) {
            const galleryData = extractUniqueGalleries(data);
            updateGalleries(data);
            updateArtworks(data, map);
        } else {
            console.log(data.message);
        }
    } catch (error) {
        console.error('Error fetching artist artworks:', error);
    } finally {
        Spinner.hide();
    }
}

export async function getCountryArtworks(map, countryId) {
    Spinner.show();
    try {
        const url = `/api/country_artworks/${countryId}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (!data.error) {
            const galleryData = extractUniqueGalleries(data);
            updateGalleries(data);
            updateArtworks(data, map);
        } else {
            console.log(data.message);
        }
    } catch (error) {
        console.error('Error fetching country artworks:', error);
    } finally {
        Spinner.hide(); // Ensure spinner is hidden regardless of success or failure
    }
}
