class ArtCard extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.render();
  }

  set artworkData(data) {
    this._artworkData = data;
    this.render();
  }

  render() {
    if (!this._artworkData) return;

    const {
      artworkId,
      galleryId,
      artistId,
      artworkTitle,
      artworkYear,
      artworkStyle,
      artworkMedium,
      artworkDimensions,
      galleryTitle,
      artistTitle,
      imageUrl,
      countryId,
      countryTitle,
      galleryLat,
      galleryLon
    } = this._artworkData;

    // Set the attributes on the art-card element
    this.setAttribute('artist-id', artistId);
    this.setAttribute('gallery-id', galleryId);
    this.setAttribute('artwork-id', artworkId);

    const template = document.createElement('template');
    template.innerHTML = `
          <style>
            :host {
              display: block;
              border: 1px solid #e0e0e0;
              background-color: #ffffff;
              width: 100%;
              box-sizing: border-box;
              border-radius: 12px;
              margin-top: 12px;
            }

            .art-card {
              display: flex;
              height: 100%;
              border-radius: 12px;
            }

            .art-card-image {
              width: 72px;
              height: 96px;
              background-image: url('${imageUrl}');
              background-size: cover;
              background-repeat: no-repeat;
              background-position-x: left;
              background-position-y: top;
              flex-shrink: 0; /* prevent image from shrinking */
            }

            .art-card-content {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              padding: 4px 10px 0px 10px;
              flex-grow: 1;
            }

            .art-card-content h3 {
              margin: 0;
              font-size: 1.1em;
              color: #333;
              font-weight: 600;
            }

            .art-card-content p {
              margin: 5px 0;
              color: #666;
              font-size: 1em;
            }

            .art-card-content p:last-of-type {
              margin-top: auto;
            }

          </style>
          <div class="art-card">
            <div class="art-card-image"></div>
            <div class="art-card-content">
              <h3>${artworkTitle}</h3>
              <p>${artistTitle} (${artworkYear})</p>
              <p>${galleryTitle}, ${countryTitle}</p>
            </div>
          </div>
        `;

    this.shadowRoot.innerHTML = '';
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    const artCard = this.shadowRoot.querySelector('.art-card');
    let holdTimer;
    const holdDuration = 1000;

    // Function to start the hold timer
    function startHoldTimer() {
      holdTimer = setTimeout(() => {
        // Trigger your hold action here
        this.dispatchEvent(new CustomEvent('artwork-hold', {
          detail: {
            artistId,
            galleryId,
            artworkId,
            artistTitle,
            artworkTitle,
            artworkYear,
            artworkStyle,
            artworkMedium,
            artworkDimensions,
            galleryTitle,
            countryTitle,
            countryId,
            imageUrl,
          },
          bubbles: true,
          composed: true
        }));
      }, holdDuration);
    }

    // Function to clear the hold timer
    function clearHoldTimer() {
      clearTimeout(holdTimer);
    }

    // Event listeners for mouse events (desktop)
    artCard.addEventListener('mousedown', startHoldTimer.bind(this), { passive: true });
    artCard.addEventListener('mouseup', clearHoldTimer, { passive: true });
    artCard.addEventListener('mouseleave', clearHoldTimer, { passive: true });

    // Event listeners for touch events (mobile)
    artCard.addEventListener('touchstart', startHoldTimer.bind(this), { passive: true });
    artCard.addEventListener('touchend', clearHoldTimer, { passive: true });
    artCard.addEventListener('touchcancel', clearHoldTimer, { passive: true });

    // Event listener for a normal click or tap
    artCard.addEventListener('click', () => {
      clearHoldTimer(); // Ensure hold doesn't trigger
      this.dispatchEvent(new CustomEvent('artwork-click', {
        detail: {
          lat: galleryLat,
          lon: galleryLon
        },
        bubbles: true,
        composed: true
      }));
    });
  }
}

customElements.define('art-card', ArtCard);
