import '../css/style.css';
import 'ol/ol.css';  // Import the OpenLayers CSS
import './art-card.js';
import './mini-art-card.js';
import './gallery-card.js';
import { initializeMap, callGeoLocation } from './map.js';
import { initializeEventListeners } from './events.js';
import { Spinner } from './spinner.js';
// import { apiFetchArtworks } from './api-queries.js';

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
        .then(registration => {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
            console.log('Service Worker registration failed:', error);
        });
    });

}


document.addEventListener('DOMContentLoaded', function() {

    Spinner.init('map');
    const map = initializeMap();
    
    // analytics
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (navigator.standalone || isStandalone) {
        gtag('event', 'pwa', {
            'event_category': 'running',
            'event_label': 'standalone',
        });
    } else {
        gtag('event', 'pwa', {
            'event_category': 'running',
            'event_label': 'browser',
        });
    }
        
    callGeoLocation(map)
        .then(() => {
            initializeEventListeners(map);    

            console.log(" -- Artfinder app initialised -- ");
        })
        .catch((error) => {
            console.error("Error occurred:", error);
        });
        
});

